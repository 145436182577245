import React, { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { atoms, store } from "../../jotai/store";
import styled from "styled-components";
import { ContentsStyled } from "../Style/StyledComponents";
import Font from "../Style/FontStyle";
import Color from "../Style/ColorStyle";
import Button from "../Button";
import Shadow from "../Style/ShadowStyle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMySize } from "../MySizeContext";
import { open_link } from "../../utils/openLink";

const EventFloting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname, hash, search} = location;
  const queryParams = new URLSearchParams(search).toString();

  const [textGroup, setTextGroup] = useState([]);
  const [buttonGroup, setButtonGroup] = useState([]);

  const {isMobile} = useMySize();
  const use_event_floting = true;
  const event_floting = useAtomValue(atoms.event_floting);
  const event_floting_data = {
    all: {
      textGroup: [
        [{point: false, text: '시럽프렌즈 QR/NFC 오더 출시'}], 
        [{point: true, text: '1+1 무료체험 이벤트 '}, {point: false, text: '진행중'}]],
      buttonGroup: [
        {type:'link', theme:'white', text:'이벤트 보러가기', link:'/event/detail/1'}, 
        {type:'callback', theme:'black', text:'이벤트 신청하기', callback:() => open_link(navigate, queryParams, "https://walla.my/syrupfriends")}, 
      ]
    },
    apply:{
      textGroup: [
        [{point: false, text: '포토리뷰 쓰면 네이버페이 전원지급!'}], 
        [{point: true, text: '스마트스토어 포토리뷰 이벤트 '}, {point: false, text: '진행 중'}]],
      buttonGroup: [
        {type:'link', theme:'black', text: isMobile ? '바로가기' : '이벤트 보러가기', link:'/event/detail/6'}, 
      ]
    }
  };

  const focus_list = {
    // all: ['', 'point', 'qr-waiting', 'ceo-plaza', 'tablet-order', 'menu-order', 'qr-order','syrup-order', '#QnA'], 
    apply: ['', 'point', 'ceo-plaza', 'popup-store', 'qr-waiting', 'syrup-order', 'tablet-order', 'menu-order', 'qr-order','#QnA']
  };

  useEffect(() => {
    if(use_event_floting) set_event_floting();
    else store.set(atoms.event_floting, false);
  }, [location]);

  const set_event_floting = () => {
    if(focus_list.all?.some(item => (pathname + hash) === ('/' + item))) {
      store.set(atoms.event_floting, {isFocus: true, type:'all'});
      setTextGroup([...event_floting_data['all'].textGroup])
      setButtonGroup([...event_floting_data['all'].buttonGroup])
    } else if (focus_list.apply?.some(item => (pathname + hash) === ('/' + item))) {
      store.set(atoms.event_floting, {isFocus: true, type:'apply'});
      setTextGroup([...event_floting_data['apply'].textGroup])
      setButtonGroup([...event_floting_data['apply'].buttonGroup])
    } else {
      store.set(atoms.event_floting, false);
    }
  }

  return(
    event_floting.isFocus && (
      <Container>
        <Contents>
          <LeftGroup>
            {textGroup.map((item, index) => (
              <Font.Title2B key={index}>
                {item.map((item, index) => {
                  if(item.point) return <span key={index} style={{color: Color.main500}}>{item.text}</span>;
                  else return item.text;
                })}
              </Font.Title2B>
            ))}
            </LeftGroup>
          <RightGroup>
            {buttonGroup.map(item => {
              if(item.type === 'link')
                return (
                  <Link to={{pathname: item.link, search: queryParams}} style={{display:'flex', flex:1, textDecoration: "none"}}>
                    <EventButton theme={item.theme}>
                      <Font.Button1M>{item.text}</Font.Button1M>
                    </EventButton>
                  </Link>
                )
              else if(item.type === 'callback')
                return (
                  <Button onClick={() => item.callback()} style={{display:'flex', flex:1}}>
                    <EventButton theme={item.theme}>
                      <Font.Button1M>{item.text}</Font.Button1M>
                    </EventButton>
                  </Button>
                )
            })}
          </RightGroup>
        </Contents>
      </Container>
    )
  )
}
const Container = styled.section`
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 100px;
  z-index: 20;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.50);
  align-items: center;
  justify-content: center;
  @media (max-width:767px){
    height: 64px;
  }
`;
const Contents = styled(ContentsStyled)`
  flex-direction: row;
  justify-content: space-between;
`;
const LeftGroup = styled.div`
  display: flex; 
  flex-direction: column;
`;
const RightGroup = styled.div`
  display: flex; 
  flex-direction: row;
  gap: 20px;
  @media (max-width: 767px){
    gap: 16px;
  }
`;

const EventButton = styled.div`
  display: flex;
  height: 56px;
  padding:0 32px;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme === 'white' ? Color.secondary500 : Color.gray100};
  background-color: ${props => props.theme === 'white' ? Color.gray100 : Color.secondary500};
  &:hover{
    background-color: ${props => props.theme === 'white' ? Color.gray300 : Color.secondary600};
  }
  &:active{
    background-color: ${props => props.theme === 'white' ? Color.gray400 : Color.secondary800};
  }
  filter: ${Shadow.new_shadow4};
  @media (max-width: 767px){
    height: 40px;
    padding: 0 16px;
  }
`;
export default EventFloting;