import React, { Suspense, lazy } from "react";
import { ReactChannelIO } from "react-channel-plugin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "jotai";
import { MySizeProvider } from "./Component/MySizeContext";
import Modal from "./Component/Modal/Modal"
import {store} from "./jotai/store"

const Home = lazy(() => import("./Pages/HomePage/Index"));
const Point = lazy(() => import("./Pages/PointPage/Index"));
const Data = lazy(() => import("./Pages/DataPage/Index"));
const PopupStore = lazy(() => import("./Pages/PopupStore/Index"));
const QRWaiting = lazy(() => import("./Pages/QRWaitingPage/Index"));
const Order = lazy(() => import("./Pages/OrderPage/Index"));
const TableOrder = lazy(() => import("./Pages/TabletOrderPage/Index"));
const MenuOrder = lazy(() => import("./Pages/MenuOrderPage/Index"));
const QROrder = lazy(() => import("./Pages/QROrderPage/Index"));
const EventList = lazy(() => import("./Pages/EventPage/List/Index"));
const EventDetail = lazy(() => import("./Pages/EventPage/Detail/Index"));
const Register = lazy(() => import("./Pages/RegisterPage/Detail/Index"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage"));
const LinkPage = lazy(() => import("./Pages/LinkPage"));

// const Subscribe = lazy(() => import("./Pages/SubscribePage/Index"));

const App = () => {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ReactChannelIO
          pluginKey={"50e37813-f2ee-45d0-82ad-2d2df673fe49"}
          hideChannelButtonOnBoot={true}
          language="ko"
          autoBoot
        >
          <MySizeProvider>
            <Suspense fallback={<div />}>
              <Modal />
              <Routes>
                <Route path="/" exact Component={QROrder} />
                {/* <Route path="/" exact Component={Home} /> */}

                {/* client -> point */}
                <Route path="/client" exact Component={Point} />
                <Route path="/point" exact Component={Point} />

                {/* data -> ceo-plaza */}
                <Route path="/data" exact Component={Data} />
                <Route path="/ceo-plaza" exact Component={Data} />

                {/* tablet-waiting -> popup-store */}
                <Route path="/tablet-waiting" exact Component={PopupStore} />
                <Route path="/popup-store" exact Component={PopupStore} />

                {/* waiting -> qr-waiting  */}
                <Route path="/waiting" exact Component={QRWaiting} />
                <Route path="/qr-waiting" exact Component={QRWaiting} />

                {/* order -> syrup-order  */}
                <Route path="/order" exact Component={Order} />
                <Route path="/syrup-order" exact Component={Order} />

                {/* tableorder -> tablet-order */}
                <Route path="/tableorder" exact Component={TableOrder} />
                <Route path="/tablet-order" exact Component={TableOrder} />

                <Route path="/menu-order" exact Component={MenuOrder} />
                <Route path="/qr-order" exact Component={QROrder} />
                <Route path="/event/list/:id" exact Component={EventList} />
                <Route path="/event/detail/:id" exact Component={EventDetail} />
                <Route path="/register/:type/:id" exact Component={Register} />

                <Route path="/link" exact Component={LinkPage} />
                {/* <Route path="/subscribe" exact Component={Subscribe} /> */}
                <Route path="*" exact Component={ErrorPage}/>
              </Routes>
            </Suspense>
          </MySizeProvider>
        </ReactChannelIO>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
