import React from "react";
import PopUp from "./PopUp";
import EventFloting from "./EventFloating";

const Modal = () => {
  return (
    <> 
      <PopUp />
      <EventFloting />
    </>
  );
};

export default Modal;