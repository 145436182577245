export const naverLog = () => {
  const script1 = document.createElement("script");
  script1.src = "//wcs.naver.net/wcslog.js";
  script1.async = true;
  document.body.appendChild(script1);

  const script2 = document.createElement("script");
  script2.type = "text/javascript";
  script2.innerHTML = `
  if (!window.wcs_add) var wcs_add={};
  wcs_add["wa"] = "s_17ccb6c09d94";
  var _nasa={};
  if (window.wcs) {
    _nasa["cnv"] = wcs.cnv("4","0");
    wcs_do(_nasa);
  }
  `;
  document.body.appendChild(script2);
};