import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

import 'swiper/css';

import Font from "../Style/FontStyle";
import Color from "../Style/ColorStyle";
import uncheckSRC from "../../assets/image/all_icon_checkbox-uncheck.svg";
import checkSRC from "../../assets/image/all_icon_checkbox-check.svg";
import Button from "../Button";
import { naverLog } from "../../utils/NaverLog";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { atoms, store } from "../../jotai/store";

const PopUp = () => {
  const navigate = useNavigate();
  const {pathname, search} = useLocation();
  const queryParams = new URLSearchParams(search).toString();
  const [check, setCheck] = useState(false);
  const [swiper_index, set_swiper_index] = useState(1);
  const pop_up = useAtomValue(atoms.pop_up);

  const skip_link = ['/event/', '/register/']

  useEffect(() => {
    const skip = skip_link.some(item => pathname.startsWith(item));
    console.log('skip', skip, pathname)
    if(skip) store.set(atoms.pop_up, false);
    else{
      if (window.localStorage.getItem("popup") !== null) 
        store.set(atoms.pop_up, new Date(window.localStorage.getItem("popup")).getDate() !== new Date().getDate());
      else store.set(atoms.pop_up, true);
    }
  }, []);

  useEffect(() => {
    if (pop_up) document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, [pop_up]);

  const popup_img = [
    {navigate:'/event/detail/4', src:'popup4'},
  ]
  return (
    pop_up && (
      <Container>
        <PopUpView>
          <SwiperDiv>
            <IndexGroup>
              <Font.Body1R>{`${swiper_index}/${popup_img.length}`}</Font.Body1R>
            </IndexGroup>
            <Swiper
              spaceBetween={0}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              loop={true}
              modules={[Autoplay]}
              onResize={({autoplay}) => setTimeout(() => {autoplay.paused && autoplay.start()}, 100)}
              onRealIndexChange={({realIndex}) => set_swiper_index(realIndex + 1)}
              >
                {popup_img.map((item, index)=>(
                  <SwiperSlide key={index}>
                    <ImageButton
                      onClick={() => {
                        naverLog();
                        navigate({pathname: item.navigate, search: queryParams});
                        store.set(atoms.pop_up, false);
                      }}
                    >
                      <Image src={require(`../../assets/image/event/popup/${item.src}.png`)} />
                    </ImageButton>
                  </SwiperSlide>
                ))}
            </Swiper>
          </SwiperDiv>
          <Bottom>
            <CheckBoxDiv onClick={() => setCheck(!check)}>
              <CheckBoxImage src={check ? checkSRC : uncheckSRC} />
              <Font.Customer
                font={Font.FontType.Button1M}
                mobile_font={Font.FontType.Button3R}
                color={Color.gray100}
              >
                오늘 하루 그만보기
              </Font.Customer>
            </CheckBoxDiv>
            <CloseDiv
              onClick={() => {
                check && window.localStorage.setItem("popup", new Date());
                store.set(atoms.pop_up, false);
              }}
            >
              <Font.Customer
                font={Font.FontType.Button1M}
                mobile_font={Font.FontType.Button3R}
                color={Color.gray100}
              >
                닫기
              </Font.Customer>
            </CloseDiv>
          </Bottom>
        </PopUpView>
      </Container>
    )
  );
};

export default PopUp;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999;
`;

const PopUpView = styled.div`
  display: flex;
  width: 560px;
  height: auto;
  border-radius: 30px;
  overflow: hidden;
  z-index: 100;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 320px;
  }
`;

const SwiperDiv = styled.div`
  position: relative;
  height: 560px;
  @media (max-width: 767px){
    height: 320px;
  }
`;
const IndexGroup = styled.div`
  display: flex;
  position: absolute;
  z-index: 110;
  top: 24px;
  right: 24px;
  height: 32px;
  padding: 0 16px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color:${Color.gray100};
  background-color: rgba(0, 0, 0, 0.40);
  @media (max-width: 767px){
    top: 16px;
    right: 16px;
    height: 30px;
  }
`;
const ImageButton = styled(Button)`
  height: 560px;
  @media (max-width: 767px) {
    height: 320px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 560px;
  @media (max-width: 767px) {
    height: 320px;
  }
`;

const Bottom = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.2);

  backdrop-filter: blur(6px);
  @media (max-width: 767px) {
    height: 56px;
  }
`;

const CheckBoxDiv = styled(Button)`
  display: flex;
  gap: 8px;
  height: 100%;
  padding: 40px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 24px;
  }
`;

const CheckBoxImage = styled.img`
  width: 24px;
  height: 24px;
  @media (max-width: 767px) {
    width: 20px;
    height: 24px;
  }
`;

const CloseDiv = styled(Button)`
  display: flex;
  height: 100%;
  padding: 40px;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 24px;
  }
`;
